import * as React from 'react';
import logo1 from '../img/logo1.png'
import logo11 from '../img/logo11.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faDollar, faParachuteBox, faStar, faStarAndCrescent, faStarHalf, faStarHalfStroke, faStarOfLife, faTag, faTimes, faTimesCircle, faVideo } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

// import Typography from '@mui/material/Typography';




import './indexfirstpage.css'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    imgPath :
      'https://images.pexels.com/photos/3974149/pexels-photo-3974149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
   
    imgPath:
      'https://images.pexels.com/photos/417059/pexels-photo-417059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    
    imgPath:
      'https://images.pexels.com/photos/161173/rio-de-janeiro-olympics-2016-niteroi-brazil-161173.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
   
    imgPath:
      'https://images.pexels.com/photos/712392/pexels-photo-712392.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
];

function IndexFirstPage() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
        }
      }
    ]
  };


  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [value, setValue] = React.useState(dayjs('2024-02-11'));
  return (
   <div style={{height:"100vh",width:"100%"}}>
     <Box sx={{ maxWidth: 1370, flexGrow: 0 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 0,
          pl: 0,
          bgcolor: 'background.default',
        }}
      >
        <Typography >{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
          
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height:665,
                  display: 'block',
                  maxWidth: 1400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    <div className='seconddiv'>
      <h1>Select Your Free Hours</h1>
      <div className='seconddivinput'>
      
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker label="From" defaultValue={dayjs('2024-02-01')} />
        <DatePicker
          label="To"
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
         <button className='submit'>Submit</button>
      </DemoContainer>
     
    </LocalizationProvider>
      </div>
      <div className='BOOKNOW'>
        <h2>BOOK NOW</h2>
      </div>
      <div className='trending'>
        <h2>Trending Sky Odyssy</h2>
      </div>
      <div className='cards'>
      <div className='cardsdetailsone'>
      <Card  sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://images.unsplash.com/photo-1630879937467-4afa290b1a6b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Sky Diving
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
    <Card sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://www.visitdubai.com/-/media/gathercontent/poi/h/helidubai/fallback-image/heli-dubainewjpg.jpg?rev=a3b7752270824f39b0848947251e660f&cx=0.5&cy=0.5&cw=1556&ch=690"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Dubai Helicopter Tour by HeliDubai
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
    <Card sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/06/e7/9a/46.jpg"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Xline Dubai
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
   
    </div>
    <div className='cardsdetailstwo'>
    <Card sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/25/48/ed/8c/on-the-way-to-our-authentic.jpg?w=1200&h=1200&s=1"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Dubai Hot Air Balloon Flight
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
    <Card sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/2c/fd/57.jpg"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        iFly Dubai Indoor Skydiving
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
    <Card sx={{ maxWidth: 500,marginLeft:10 }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://cdn.getyourguide.com/img/tour/640eb9a305f43.jpeg/145.jpg"
        title="Sky Diving"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Gyrocopter Flight
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Some quick example text to build on the card title and make up the bulk of the card's content.
        </Typography>
      </CardContent>
    </Card> 
      </div>
      </div>
      <div className='p'>
      <h3>More Sky Odyssey</h3>
      < FontAwesomeIcon className='fonticon' type='icon' icon={faParachuteBox}></ FontAwesomeIcon >
      </div>
      <div className='BOOKNOW'>
        <h2>Book Now</h2>
      </div> 
      <div className='trending'>
        <h2>Featured Water Quest</h2>
       <div className='a'>
        <div className='b'>
          <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/28/c3/73/b1/caption.jpg?w=500&h=400&s=1" alt="" />
          <h5>Jet Ski Tour in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='c'>
        <img src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/ca/e8/b4.jpg" alt="" />
          <h5>Parasailing Experience in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='d'>
        <img src="https://cdn.getyourguide.com/img/tour/314bd9f3c5007150.jpeg/145.jpg" alt="" />
          <h5>Dubai Marina: Yacht Tour</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='e'>
        <img src="https://res.klook.com/image/upload/c_fill,w_750,h_560/q_80/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/nxaktgowmqr5q70ii7uq.jpg" alt="" />
          <h5>Banana Boat Ride in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div>
        </div>
        <div className='f'>
        <div className='bf'>
          <img src="https://venusdestination.com/wp-content/uploads/2021/11/jetovator-tour-in-dubai1.jpg" alt="" />
          <h5>Jetovator Tour in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='cf'>
          <img src="https://www.globaltimes.cn/Portals/0/attachment/2021/2021-07-15/834c643d-2e42-4fac-87ec-fbcebcbaff4c.jpeg" alt="" />
          <h5>Deep Dive in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='df'>
          <img src="https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/10/Keey2LUX-Best-places-to-go-scuba-diving-in-the-UAE.jpg" alt="" />
          <h5>Scuba Diving in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        <div className='ef'>
          <img src="https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1295,h_861/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/qcageojkiqv5mixnmpf0/CrystalClearKayakingExperienceinDubai.jpg" alt="" />
          <h5>Crystal Clear Kayaking Experience in Dubai</h5>
          <FontAwesomeIcon type='icon'  icon={faDollar}></FontAwesomeIcon> <b>225.00</b>
        </div>
        </div>
       </div>
      </div> 
      <div>
      </div>
    <div className='more'>
      <h5>More Water Quests</h5>
    </div>
    <div className='BOOKNOWs'>
        <h2>Book Now</h2>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='BOOKNOWss'>
        <h2>Our Most Popular WildScapes</h2>
      </div>
      <div className='cardmain'>
         <div className='cardsecmain'>
        <h5 className='mainh5'><FontAwesomeIcon icon={faClock}></FontAwesomeIcon>  5 Days | 259p,Himalaya Ag <button className='mainbutton'><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon>120</button></h5>
        <h2 className='mainh2'>Quad Biking</h2>
        <div className='mainstar'>
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
        <b className='bold'>  5 review</b>
        </div>
        <div className='para'>
          <p>Richely Varied Landscapes,Luxurious Accomodations And a Wild Range of Wild Expriences Await You</p>
        </div>
        <div className='details'>
           <h5><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>  View Details   <FontAwesomeIcon icon={faTag}></FontAwesomeIcon></h5>
        </div>
         </div>
         <div className='cardthirdmain'>
         <h5 className='mainh5'><FontAwesomeIcon icon={faClock}></FontAwesomeIcon>  5 Days | 259p,Himalaya Ag <button className='mainbutton'><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon>120</button></h5>
        <h2 className='mainh2'>Quad Biking</h2>
        <div className='mainstar'>
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
        <b className='bold'>  5 review</b>
        </div>
        <div className='para'>
          <p>Richely Varied Landscapes,Luxurious Accomodations And a Wild Range of Wild Expriences Await You</p>
        </div>
        <div className='details'>
           <h5><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>  View Details   <FontAwesomeIcon icon={faTag}></FontAwesomeIcon></h5>
        </div>
         </div>
         <div className='cardfourmain'>
         <h5 className='mainh5'><FontAwesomeIcon icon={faClock}></FontAwesomeIcon>  5 Days | 259p,Himalaya Ag <button className='mainbutton'><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon>120</button></h5>
        <h2 className='mainh2'>Quad Biking</h2>
        <div className='mainstar'>
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
        <b className='bold'>  5 review</b>
        </div>
        <div className='para'>
          <p>Richely Varied Landscapes,Luxurious Accomodations And a Wild Range of Wild Expriences Await You</p>
        </div>
        <div className='details'>
           <h5><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>  View Details   <FontAwesomeIcon icon={faTag}></FontAwesomeIcon></h5>
        </div>
         </div>
         <div className='cardfivemain'>
         <h5 className='mainh5'><FontAwesomeIcon icon={faClock}></FontAwesomeIcon>  5 Days | 259p,Himalaya Ag <button className='mainbutton'><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon>120</button></h5>
        <h2 className='mainh2'>Quad Biking</h2>
        <div className='mainstar'>
        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon><FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
        <b className='bold'>  5 review</b>
        </div>
        <div className='para'>
          <p>Richely Varied Landscapes,Luxurious Accomodations And a Wild Range of Wild Expriences Await You</p>
        </div>
        <div className='details'>
           <h5><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>  View Details   <FontAwesomeIcon icon={faTag}></FontAwesomeIcon></h5>
        </div>
      </div>
      </div>
   <div className='moredetails'>
    <button><h1>More   Details  <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
    
    >
      <path d="M12 9c1.59 7.61-2 13-2 13h3c1.88-5.8 1-9.91.5-12m2.16-2.84c.17.21.34.43.47.66a7.1 7.1 0 01-.63 8.44 7.11 7.11 0 00-.55-6.49c-.08-.13-.17-.24-.25-.36a7.123 7.123 0 00-2.16-1.98 7.127 7.127 0 00-4.96 6.79c0 .74.11 1.45.31 2.11a7.073 7.073 0 01-1.33-4.14c0-2.35 1.14-4.43 2.89-5.73C8 6.35 6.46 6.67 5.12 7.5c-.62.41-1.16.88-1.62 1.41.55-1.33 1.5-2.52 2.8-3.34 1.5-.94 3.2-1.25 4.84-1.01C10.73 4 10.23 3.47 9.63 3c-.58-.42-1.21-.76-1.87-1 1.44.04 2.88.5 4.11 1.43.63.47 1.13 1.04 1.53 1.64.1 0 .19-.01.29-.01 3.2 0 5.91 2.11 6.81 5.02a7.073 7.073 0 00-4.84-2.92z" />
    </svg></h1></button>
   <br />
<br />
   </div>


  <div className="footer">
   <div className='imgone'>
   <img src={logo1} alt="" />
   <p>ExciteRoute Was Found In 2023</p>
   <div className='fa'>
   <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="4em"
      width="2em"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
    </svg> 

    <svg style={{padding:"10px"}}
      viewBox="0 0 960 1000"
      fill="currentColor"
      height="5em"
      width="3em"
     
    >
      <path d="M480 20c133.333 0 246.667 46.667 340 140s140 206.667 140 340c0 132-46.667 245-140 339S613.333 980 480 980c-132 0-245-47-339-141S0 632 0 500c0-133.333 47-246.667 141-340S348 20 480 20m196 392c20-14.667 35.333-30.667 46-48-21.333 8-39.333 12.667-54 14 20-12 34-29.333 42-52-20 10.667-40 18-60 22-18.667-18.667-42-28-70-28-26.667 0-49 9.333-67 28s-27 40.667-27 66c0 1.333.333 4.667 1 10s1 9.333 1 12c-80-4-144.667-37.333-194-100-9.333 16-14 32-14 48 0 33.333 14.667 59.333 44 78-17.333 0-32-4-44-12v2c0 22.667 7 42.667 21 60s32.333 28 55 32c-10.667 2.667-18.667 4-24 4-8 0-14-.667-18-2 13.333 44 42.667 66 88 66-33.333 26.667-72.667 40-118 40h-22c45.333 28 93.333 42 144 42 81.333 0 146.667-27.667 196-83s74-117.667 74-187v-12" />
    </svg>

    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="4em"
      width="2em"
      
    >
      <path d="M941.3 296.1a112.3 112.3 0 00-79.2-79.3C792.2 198 512 198 512 198s-280.2 0-350.1 18.7A112.12 112.12 0 0082.7 296C64 366 64 512 64 512s0 146 18.7 215.9c10.3 38.6 40.7 69 79.2 79.3C231.8 826 512 826 512 826s280.2 0 350.1-18.8c38.6-10.3 68.9-40.7 79.2-79.3C960 658 960 512 960 512s0-146-18.7-215.9zM423 646V378l232 133-232 135z" />
    </svg>
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="4em"
      width="2em"
      
    >
      <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 01-47.9 47.9z" />
    </svg>
   </div>
   </div>
   <div className='sree'>
    <div className='sankara'>
     <h4>Explore ExciteRoute</h4>
     <p>Account</p>
     <p>Privacy Policy</p>
     <p>Affiliate</p>
     <p>Program</p>
     <p>Our Partner</p>
    </div>
   <div className='acharya'>
    <h4>Quick Links</h4>
    <p>About Us</p>
    <p>News And Press</p>
    <p>Blogs</p>
    <p>FAQs</p>
    <p>Careers</p>
       </div>
    <div className="university">
    <h4>Address</h4>
     <p>Europe 45 Gloucester Road London <br />DT1M 3BF +44 (0)20 3671 5709</p>
     <p>Europe 45 Gloucester Road London <br />DT1M 3BF +44 (0)20 3671 5709</p>
     
    </div>
   
   </div>
   <div className='last'>
    <div className='l'>
    <b>Copyright © 2023</b>
    </div>
    <div  className='as'>
<img src={logo11} alt="" />
    </div>
    <div className='t'>
   <b>. All Rights Reserved Designed by</b>
    </div>
    <div className='q'>
    <img src={logo11} alt="" />
    </div>
   </div>
  </div>



      </div> 
    </div>
  );
}

export default IndexFirstPage;