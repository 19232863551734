import React from "react";
// import {BrowserRouter,Routes,Route} from 'react-router-dom'
// import WaterQuest from "./component3WaterQuest/WaterQuest";
// import WildScapes from "./component4wildscapes/WildScapes";
//  import TestPage from "./component4Testpage/TestPage";
import IndexFirstPage from './componentFirstpage/IndexFirstPage'
// import FirstOne from "./components/FirstOne";
// import SecondPage from "./components/SecondPage";
// import ThirdPage from "./components/ThirdPage";
// import Footer from "./components/Footer";
// import LastFooter from "./components/LastFooter";
// import FirstPage from "./components2/FirstPage";
// import Footer from "./components2/Footer";
// import AnotherFooter from "./components2/AnotherFooter";

function App() {
  return (
    <div className="App">
      {/* <FirstOne/>
     <SecondPage/>
     <ThirdPage/>
     <Footer/>
     <LastFooter/> */}
     {/* <FirstPage/>
     <Footer/>
     <AnotherFooter/> */}
    {/* <WaterQuest/>  */}
    {/* <WildScapes/> */}
    {/* <TestPage/> */}
    <IndexFirstPage/>
  
    </div>

  );
}

export default App;
